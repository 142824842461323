import { Component, Input, Output, EventEmitter } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SupportService} from 'src/app/services/support.service';
import { state } from '@app/utility'
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { IUserInfo } from 'src/app/interfaces';


@Component({
  selector: 'app-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss'],
})
export class TicketFormComponent {
  @Output() toggleContactPanel = new EventEmitter<boolean>();
  @Output() onContactFormSubmit = new EventEmitter<any>();
  @Input() isPanelOpen! : boolean;
  uploadedFiles: any[] = [];
  ticket: any = { email: '', featureType: '', subject: '', description: '' };
  UploadDatasetForm!: FormGroup;
  fileData : string[] = [];
  userInfo: IUserInfo;
  isTypeValid : boolean = true;
  isSubjectValid : boolean = false;
  isDescriptionValid: boolean = false;
  ticketForm! : FormGroup;
  hideAttachments: boolean = true;
  files! : File[];
  isFileUploading: boolean = false;
  errorState = '';
  showErrorToast = false;

  constructor(private support: SupportService, private contactFormBuilder: FormBuilder){
    this.userInfo = state.getUserInfo();
    this.ticket.email = this.userInfo.email;
  }

  ngOnInit(){
    this.ticketForm = this.contactFormBuilder.group({
      email: [{ value: this.userInfo.email, disabled: true }],
      featureType: ['bug', [Validators.required]],
      subject: ['', [Validators.required]],
      description: ['', [Validators.required]],
      attachments: ''
    });
  }
  closeContactPanel(value: boolean) {
    this.toggleContactPanel.emit(value);;
  }
  onRadioChange(event:any) {
    this.ticket.featureType = event.target.value
  }
  toggleTicketSubmit() {
    this.closeContactPanel(false);
  }

  async onFileUpload(uploadedFile:any){ 
    try{
      this.files =  Array.from(uploadedFile)
      if((this.fileData.length + this.files.length) <= 5){
        const formData = new FormData();
        for (let i=0; i<= this.files.length; i++){
          formData.append('file', this.files[i])
        }
        this.isFileUploading = true;
        if(this.files.length) {
          const uploadedFileKeys = await lastValueFrom(this.support.uploadTicketFile(formData , 'contact-us' ))
          for(let i=0;i<uploadedFileKeys.length;i++) {
            this.uploadedFiles[i] = {
              ...this.uploadedFiles[i],
              id: uploadedFileKeys[i]
            }
          }
          this.fileData = [...this.fileData, ...uploadedFileKeys];
        }
        this.isFileUploading = false;
      }  
    }
    catch(error){ 
      this.showErrorToast = true;
      this.errorState = 'toast-error';
    }
  }
  async submitForm() {
    const formData = {
    'email': this.ticket.email,
    'type': this.ticket.featureType?.length ? this.ticket.featureType : 'bug',
    'subject': this.ticket.subject,
    'description': this.ticket.description,
    'attachments': this.fileData
    }
 
    await lastValueFrom( this.support.postUserData(formData));
    this.uploadedFiles = [];
    this.hideAttachments = true;
    this.toggleTicketSubmit();
    this.onContactFormSubmit.emit()
  }

  onFileSelected(event: any) {
    if ((this.uploadedFiles.length + event.target.files.length) > 5) {
      this.hideAttachments = false;
    }
    else{
      const selectedFiles = [];
      for (const file of event.target.files) {
        if (file.size <= 1000000) {
          this.uploadedFiles.push({
            value: file
          });
          selectedFiles.push(file);
        }
      }      
      this.onFileUpload(selectedFiles);
      if( this.uploadedFiles.length === 5) this.hideAttachments = false;
    }
  }

  removeFile(id: string,index: number) {
    const filteredArray = this.uploadedFiles.filter((item) => item.id != id);
    this.uploadedFiles = [...filteredArray]
    this.fileData.splice(index, 1);
    lastValueFrom(this.support.deleteFiles(id))
  }

  formatFileSize(size: number): string {
    if (size < 1024) {
      return size + ' B';
    } else if (size < 1024 * 1024) {
      return (size / 1024).toFixed(2) + ' KB';
    } else {
      return (size / (1024 * 1024)).toFixed(2) + ' MB';
    }
  }
}
