<div>
  <ng-container>
   <fe-micro-share-components-header [headerConfig]="config.header" ></fe-micro-share-components-header>
  </ng-container>
  <ng-container>
    <app-sidebar [sidebarConfig]="config.sidebar"></app-sidebar>
  </ng-container>
  <ng-container class="bottom-menu">
    <app-master-weather [footerConfig]="config.footer"></app-master-weather>
  </ng-container>
  @if(displayVideoStreamScreen){
    <shared-components-modal-video-galary [modalVideoPlayerScreenDetail]="modalVideoPlayerScreenDetail"></shared-components-modal-video-galary>
  }
</div>
