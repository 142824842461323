import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ticket-form-toast',
  templateUrl: './ticket-form-toast.component.html',
  styleUrls: ['./ticket-form-toast.component.scss'],
})
export class TicketFormToastComponent {
  @Input() isTicketToastOpen: any;
}
