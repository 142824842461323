export const DEFAULT_HSF_CONFIG = {
    header: {
      show: true,
      children: [
        { name: 'home', show: true },
        { name: 'launchpad', show: true },
        { name: 'search', show: true },
        { name: 'notification', show: true },
        { name: 'profile', show: true },
      ],
    },
    sidebar: {
      show: true,
      children: [
        { id: 1, icon: 'site', sidebarName: 'Assets', show: true },
        { id: 2, icon: 'mission', sidebarName: 'Mission', show: true },
        { id: 3, icon: 'overview', sidebarName: 'Overview', show: true },
        { id: 4, icon: 'mapsetting', sidebarName: 'Map Setting', show: true },
        { id: 5, icon: 'annotations', sidebarName: 'Annotations', show: true },
        { id: 6, icon: 'dashboard', sidebarName: 'Findings Dashboard', show: true },
        { id: 7, icon: 'label-management', sidebarName: 'Label Management', show: true },
        { id: 8, icon: 'inventory', sidebarName: 'Inventory Management', show: false },
        { id: 9, icon: 'equipment', sidebarName: 'Equipment', show: true },
        { id: 10, icon: 'catalogue', sidebarName: 'Catalogue/Master Library', show: false },
      ]
    },
    footer: {
      show: false,
      children: [
        // left
        { name: 'elevation', show: true },
        { name: 'celcius', show: true },
        { name: 'wind', show: true },
        { name: 'cloud', show: true },
        { name: 'moisture', show: true },
        // right
        { name: 'latlng', show: true },
        { name: 'location', show: true },
        { name: 'scale', show: true },
        { name: 'airspace', show: true },
      ],
    }
  };