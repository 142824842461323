import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment-timezone';
import { OpenMeteoService } from '../services/open-meteo.service';

@Component({
  selector: 'app-master-weather',
  templateUrl: './master-weather.component.html',
  styleUrls: ['./master-weather.component.scss'],
})
export class MasterWeatherComponent implements OnInit {
  @Input() footerConfig: any;
  weatherLabel: Object = {};
  weatherTap: Number = 0;
  isContactPanelOpen = false;
  formToast: boolean = false;
  // sample data for weather table
  sunLabel = [
    {
      day: 'Day',
      hours: 'Hours',
      mainLabel: 'Temperature',
      subLabel: 'Dew Point Temperature',
    },
  ];
  windLabel = [
    {
      day: 'Day',
      hours: 'Hours',
      mainLabel: 'Speed & Direction',
      subLabel: 'Gusts',
    },
  ];
  cloudLabel = [
    {
      day: 'Day',
      hours: 'Hours',
      mainLabel: 'Cloud Cover',
      subLabel: 'Visibility',
    },
  ];
  moistureLabel = [
    {
      day: 'Day',
      hours: 'Hours',
      mainLabel: 'Relative Humidity',
      subLabel: 'Precipitation Probability',
    },
  ];
  constructor(private openMeteo: OpenMeteoService) { }
  
  toggleContactPanel(value : any) {
    this.isContactPanelOpen = value;
  }
  onContactFormSubmit() {
    setTimeout(() => {
      this.formToast = true;
      setTimeout(() => {
        this.formToast = false;
      }, 1000);
    }, 1000);
  }
  todayDate: any;
  dailyWeatherForecast = {
    current: {
      temperature: 0,
      windSpeed: 0,
      windDirection: 0,
    },
    sunrise: {
      hour12: '',
      hour24: '',
    },
    rainFall: '',
    wind: '',
    windDirection: '',
    sunset: {
      hour12: '',
      hour24: '',
    },
    precipitation: '',
    gust: '',
    temperature: {
      unit: '',
      max: '',
      min: '',
    }
  };
  hourlyWeatherForecast: Object = [];
  ngOnInit(): void {
    this.handleWeatherPanel();
    this.getTodayDate()
    window.addEventListener('dronos:utm:footer-call', () => {
      setTimeout(() => {
        const customEvent = new CustomEvent('dronos:utm:footer-answer')
        window.dispatchEvent(customEvent)
      })
    })
    window.addEventListener('dronos:utm:change-coordinate', (event: any) => {
      const coordinate = event.detail.coordinate
      this.getTodayForecast(coordinate)
      this.getHourlyForecast(coordinate)
    })
  }
  getTodayDate() {
    this.todayDate = moment().format('dddd D MMM hh:mm')
    setInterval(() => {      
      this.todayDate = moment().format('dddd D MMM hh:mm')
    }, 5000);
  }
  getTodayForecast(coordinate: any) {
    this.openMeteo.todayForecast(coordinate).subscribe({
      next: (data: any) => {
        const current = data.current_weather
        const daily = data.daily
        const dailyUnits = data.daily_units
        const sunriseTime = new Date(daily.sunrise[0])
        const sunsetTime = new Date(daily.sunset[0])
        this.dailyWeatherForecast.sunrise = {
          hour12: this.formatTime(sunriseTime, true),
          hour24: this.formatTime(sunriseTime, false),
        }
        this.dailyWeatherForecast.sunset = {
          hour12: this.formatTime(sunsetTime, true),
          hour24: this.formatTime(sunsetTime, false),
        }
        this.dailyWeatherForecast.current = {
          temperature: current.temperature,
          windSpeed: current.windspeed,
          windDirection: current.winddirection,
        }
        this.dailyWeatherForecast.rainFall = `${daily.rain_sum[0]} ${dailyUnits.rain_sum}`
        this.dailyWeatherForecast.wind = `${daily.windspeed_10m_max[0]} ${dailyUnits.windspeed_10m_max}`
        this.dailyWeatherForecast.windDirection = this.getWindDirection(daily.winddirection_10m_dominant[0])
        this.dailyWeatherForecast.precipitation = `${daily.precipitation_probability_max[0]} ${dailyUnits.precipitation_probability_max}`
        this.dailyWeatherForecast.gust = `${daily.windgusts_10m_max[0]} ${dailyUnits.windgusts_10m_max}`
        this.dailyWeatherForecast.temperature = {
          unit: '°',
          max: `${daily.temperature_2m_max[0]}`,
          min: `${daily.temperature_2m_min[0]}`,
        }
      },
      error: (error) => {
        console.log(error)
      }
    })
  }
  getHourlyForecast(coordinate: any) {
    this.openMeteo.hourlyForecast(coordinate).subscribe({
      next: (data: any) => {
        const hourly = data.hourly
        // const hourlyUnits = data.hourly_units -> if need provided unit
        let tempHourly = []
        let tempData = []
        for (let i = 0; i < hourly.time.length; i ++) {
          const prevDate = new Date(hourly.time[i - 1])
          const prevDay = prevDate.getDate()
          const prevMonth = prevDate.getMonth()
          const prevYear = prevDate.getFullYear()
          const prevFullDate = `${prevDay}${prevMonth}${prevYear}`
          const date = new Date(hourly.time[i])
          const day = date.getDate()
          const month = date.getMonth()
          const year = date.getFullYear()
          const hour = date.getHours()
          const fullDate = `${day}${month}${year}`
          if (fullDate.toString() !== prevFullDate.toString() && i > 0) {
            const fullData = {
              day: moment(date).format('dddd, Do MMMM'),
              data: tempData
            }
            tempHourly.push(fullData)
            tempData = []
          }
          const data = {
            time: hourly.time[i],
            hour: hour + 1,
            precipitation: {
              label: this.moistureLabel,
              icon: hourly.relativehumidity_2m[i] < 30 ? 'moisture' : hourly.relativehumidity_2m[i] < 60 ? 'moisture2' : 'moisture3',
              mainValue: hourly.relativehumidity_2m[i],
              subValue: hourly.precipitation_probability[i],
            },
            temperature: {
              label: this.sunLabel,
              icon: hourly.temperature_2m[i] < 1 ? 'sun' : hourly.temperature_2m[i] > 2 ? 'sun2' : 'sun3',
              mainValue: hourly.temperature_2m[i],
              subValue: hourly.dewpoint_2m[i],
            },
            wind: {
              label: this.windLabel,
              icon: hourly.windspeed_10m[i] < 30 ? 'wind' : hourly.windspeed_10m[i] < 60 ? 'wind2' : 'wind3',
              mainValue: hourly.windspeed_10m[i],
              subValue: hourly.windgusts_10m[i],
            },
            cloud: {
              label: this.cloudLabel,
              icon: hourly.cloudcover[i] < 30 ? 'cloud' : hourly.cloudcover[i] < 60 ? 'cloud2' : 'cloud3',
              mainValue: hourly.cloudcover[i],
              subValue: hourly.visibility[i],
            }
          }
          tempData.push(data)
        }
        this.hourlyWeatherForecast = tempHourly
      }
    })
  }
  getWindDirection(degree: any) {
    let direction = ''
    if (degree >= 10 && degree < 80) direction = 'North East';
    if (degree >= 80 && degree < 100) direction = 'East';
    if (degree >= 100 && degree < 170) direction = 'South East';
    if (degree >= 170 && degree < 190) direction = 'South';
    if (degree >= 190 && degree < 260) direction = 'South West';
    if (degree >= 260 && degree < 280) direction = 'West';
    if (degree >= 280 && degree < 350) direction = 'North West';
    if (degree >= 350 || degree < 10) direction = 'North';
    return direction
  }
  formatTime(time: any, hour12: boolean) {
    let formattedTime = ''
    formattedTime = time.toLocaleTimeString([], { hour12: hour12, hour: hour12 ? 'numeric' : '2-digit', minute: '2-digit' })
    if (!hour12) {
      formattedTime = formattedTime.replace(':', '')
    }
    return formattedTime
  }
  onWeatherTap(val: number) {
    if (this.weatherTap == val) {
      this.weatherTap = 0;
    } else {
      this.weatherTap = val;
      if (this.weatherTap === 1) {
        this.weatherLabel = this.moistureLabel[0]
      }
      if (this.weatherTap === 2) {
        this.weatherLabel = this.sunLabel[0]
      }
      if (this.weatherTap === 3) {
        this.weatherLabel = this.windLabel[0]
      }
      if (this.weatherTap === 4) {
        this.weatherLabel = this.cloudLabel[0]
      }
    }
  }
  handleWeatherPanel() {
    document.addEventListener('click', (event: any) => {
      (<Element>event.target).closest('#bottomMenu') ? '' : (this.weatherTap = 0);
    });
  }

}
