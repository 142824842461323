import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service'; 
import { ISiteListResponse } from '../interfaces';
import { baseUrls } from '../shared/constants';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl() + baseUrls.INVENTORY;

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private http: HttpClient) {}

  searchSitebyName(siteName:string): Observable<ISiteListResponse> {
    return this.http.get<ISiteListResponse>(`${apiUrl}?search=${siteName}`);
  }
}
