<div class="weather-coverage">
    <div class="weather-coverage-head">
        <div class="weather-coverage-head-btn">
            <button (click)="getHours(1)" (click)="weatherHours = 1"
                [ngClass]="{ active: weatherHours == 1 }">Hourly</button>
            <button (click)="getHours(3)" (click)="weatherHours = 3" [ngClass]="{ active: weatherHours == 3 }">3
                Hour</button>
        </div>
    </div>
    <div class="weather-coverage-content">
        <div class="weather-coverage-content-left">
            <div>
                <p>{{ weatherLabel.day }}</p>
                <p>{{ weatherLabel.hours }}</p>
            </div>
            <div>
                <p>{{ weatherLabel.mainLabel }}</p>
                <p>{{ weatherLabel.subLabel }}</p>
            </div>
        </div>
        <div class="weather-coverage-content-right">
            <div class="weather-coverage-content-right-content" *ngFor="let weatherData of weatherDataList">
                <div>
                    <div class="weather-coverage-day">
                        <p>{{ weatherData.day }}</p>
                    </div>
                    <div class="weather-coverage-hours">
                        <p *ngFor="let data of weatherData.data">{{ data.hour }}</p>
                    </div>
                    <div class="weather-coverage-icon">
                        <img *ngFor="let data of weatherData.data" [src]="'/master-weather/' + data[weatherType].icon + '.svg' | assetUrl" alt="{{ data[weatherType].icon }}" />
                    </div>
                    <div class="weather-coverage-hours">
                        <p class="weather-value" *ngFor="let data of weatherData.data">{{ data[weatherType].mainValue }}</p>
                    </div>
                    <div class="weather-coverage-hours">
                        <p class="weather-value" *ngFor="let data of weatherData.data">{{ data[weatherType].subValue }}</p>
                    </div>
                </div>
                <div class="weather-coverage-content-right-content-border"></div>
            </div>
        </div>
    </div>
</div>