import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AssetUrlPipe } from './asset-url.pipe';
import { MasterWeatherComponent } from './master-weather/master-weather.component';
import { WeatherTableComponent } from './master-weather/weather-table/weather-table.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { CommonModule } from '@angular/common';
import { IconsModule } from './icons/icons.module';
import { LoaderInterceptorService } from './interceptors/loader.interceptor';
import { TooltipWithIconComponent } from './shared/tooltip-with-icon/tooltip-with-icon.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IsGrantedDirective } from './user-permissions/user-permission.directive';
import { MissionComponent } from './mission/mission.component';
import { TicketFormComponent } from './shared/ticket-form/ticket-form.component';
import { TicketFormToastComponent } from './shared/ticket-form-toast/ticket-form-toast.component';
import { ToastComponent } from './shared/toast/toast.component';
import { ModalVideoGalaryComponent } from './shared/modal-video-galary/modal-video-galary.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    AssetUrlPipe,
    MasterWeatherComponent,
    WeatherTableComponent,
    HeaderComponent,
    TooltipWithIconComponent,
    IsGrantedDirective,
    MissionComponent,
    TicketFormComponent,
    TicketFormToastComponent,
    ToastComponent
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, FormsModule, ReactiveFormsModule, CommonModule, IconsModule, NgbTooltipModule, ModalVideoGalaryComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
