import { Component, ElementRef, OnInit, Input } from '@angular/core';
import { filter } from 'rxjs';
import { assetUrl } from 'src/single-spa/asset-url';
import * as singleSpa from 'single-spa';
import { USER_PERMISSIONS } from '../user-permissions/user-permission.const';
import { PermissionManagerService } from '../user-permissions/user-permission.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(private userPermission: PermissionManagerService) {}
  @Input() sidebarConfig: any;
  on_nav_tap: any = '';
  on_mapstyle_tap = 1;

  // sites page asset filter select value
  industry: any;
  asset_health: any;
  project: any;

  ngOnInit(): void {
    window.addEventListener('single-spa:routing-event', (event: any) => {
      setTimeout(() => {
        this.checkPermission();
      });
      const pathName = new URL(event.detail.newUrl).pathname;
      const basePath = pathName.split('/')[1];

      if (basePath === '') {
        this.on_nav_tap = '';
      }
    });
    window.addEventListener('active-sidebar:mission-list', (event: any) => {
      const icon = event.detail.icon;
      this.sideBarClicked(icon);
    });
  }

  // change eye icon
  onChangeEyeIcon(event: any) {
    if (event.srcElement.src == assetUrl('/sidebar/eye.png')) {
      event.srcElement.src = assetUrl('/sidebar/eye-slash.png');
    } else {
      event.srcElement.src = assetUrl('/sidebar/eye.png');
    }
  }
  // change checkbox tick
  onChangeCheckIcon(event: any) {
    if (event.srcElement.src == assetUrl('/sidebar/un-checked.svg')) {
      event.srcElement.src = assetUrl('/sidebar/checked.svg ');
    } else {
      event.srcElement.src = assetUrl('/sidebar/un-checked.svg');
    }
  }

  // clear selected data on sites page asset filter popup box
  onClearAll() {
    this.industry = null;
    this.project = null;
    this.asset_health = null;
  }

  // common for all droupdown
  droupdown(event: any, targetElement: string = 'div.droupdownBox'): void {
    event.target.parentElement
      .closest(targetElement)
      .classList.toggle('active');
  }

  sideBarClicked(icon: any) {
    this.on_nav_tap = icon;

    const customEvent = new CustomEvent('dronos:utm:sidebar-click', {
      detail: { icon: icon },
    });
    window.dispatchEvent(customEvent);

    if (icon === 'site') {
      singleSpa.navigateToUrl('/asset-management');
    } else if (icon === 'overview') {
      singleSpa.navigateToUrl('/canvas');
    } else if (icon === 'mapsetting') {
      singleSpa.navigateToUrl('/');
    } else if (icon === 'inventory') {
      singleSpa.navigateToUrl('/inventory-management');
    } else if (icon === 'catalogue') {
      singleSpa.navigateToUrl('/inventory-management/catalogue');
    } else if (icon == 'dashboard') {
      singleSpa.navigateToUrl('/dashboard/findings');
    } else if(icon == 'label-management') {
      singleSpa.navigateToUrl('/2d-annotation/label-management');
    } else {
      singleSpa.navigateToUrl('/');
    }
  }

  checkPermission() {
    const iconToChecked = [
      { name: 'inventory', permission: USER_PERMISSIONS.INVENTORY_VIEW },
      {
        name: 'catalogue',
        permission: USER_PERMISSIONS.INVENTORY_CATALOG_VIEW,
      },
      {
        name:'dashboard',
        permission: USER_PERMISSIONS.REPORT_DASHBOARD_ASSET,
      }
    ];

    iconToChecked.forEach((toChecked: any) => {
      this.sidebarConfig.children = this.sidebarConfig.children.map(
        (sidebar: any, index: any) => ({
          ...sidebar,
          show:
            sidebar.icon === toChecked.name
              ? this.userPermission.isGranted(toChecked.permission)
              : sidebar.show,
        })
      );
    });
  }
}
