import { Component,Output,EventEmitter} from '@angular/core';
@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss'],
})
export class MissionComponent {
  folderImage: string = '../../assets/images/folder.svg';
  chartImage: string = '../../assets/images/chart.svg';
  personImage: string = '../../assets/images/person.svg';
  mailImage: string = '../../assets/images/mail.svg';
  listImage: string = '../../assets/images/list.svg';
  settingImage: string = '../../assets/images/setting.svg';

  @Output() UpdateshowMissionComponent = new EventEmitter<boolean>();
}