import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service';
import { IUserInfo } from '../interfaces';
import { baseUrls } from '../shared/constants';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl() + '/account';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUserProfile(): Observable<IUserInfo> {
    return this.http.get<IUserInfo>(`${apiUrl}/users/profile`);
  }

  logout(): Observable<any> {
    return this.http.post<any>(
      `${apiUrl}/auth/logout`,
      {},
      { observe: 'response' }
    );
  }

  getUserFilesPresignedUrl(key: string): Observable<any> {
    return this.http.get<any>(`${environmentService.getApiUrl()}${baseUrls.DATA_RESOURCE}${key}?pre_signed_url=true`);
  }
}
