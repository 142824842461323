import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, ViewChild, EventEmitter, Output, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { REWIND_TIME, FORWARD_TIME, PLAYBACK_TIME, MEDIA_SERVERS, VIDEO_STATUS, DEFAULT_PLAYBACK_SPEED } from './video-player.component.const';
import { MediaMtxWebRTCService } from '../services/mediamtx.service';
import { STREAM_SHORTLY_BEGAIN_TXT, FAILED_STREAM_VIDEO } from '../constants/shared.const';
import { StreamVideoDetail } from '../interfaces/stream-video-detail.interface';
import { MediaMtxHLSService } from '../services/mediamtx-hls.service';
import { AssetUrlStandAlonePipe } from '../asset-url-standalone-pipe';
import { AssetUrlPipe } from '../asset-url.pipe';
interface Document extends HTMLDocument {
  mozCancelFullScreen: () => void;
  webkitExitFullscreen: () => void;
  mozFullScreenElement: () => void;
  webkitFullscreenElement: () => void;
}

@Component({
  selector: 'dronos-video-player',
  standalone: true,
  templateUrl: './video-player.component.html',
  styleUrl: './video-player.component.scss',
  imports: [AssetUrlStandAlonePipe],
  providers:[AssetUrlPipe]
})
export class VideoPlayerComponent {
  public displayControllsOpacity: number = 0;
  public isPlaying: boolean = false;
  public isFullVolume: boolean = true;
  public isFullScreen: boolean = false;
  public watchedProgress: number = 0;
  public loadPercentage: Number = 0;
  public durationRemaining: string = '00:00';
  public playbackSpeedOptions: number[] = PLAYBACK_TIME;
  public currentPlaybackSpeed: number = 1.00;
  public controlsTimeout!: ReturnType<typeof setTimeout>;
  @Input() liveStream: boolean = false;
  @Input()
  videoUrl!: string;
  @Input()
  videoStreamId!: string;
  @Input() public mute: boolean = false;
  @Input() autoplay: boolean = false;
  @Output() videoStateChange = new EventEmitter<{ state: string, currentTime: number, totalTime: number }>();
  @Input()
  public mediaServer!: string;
  @ViewChild('video')
  video!: ElementRef;
  @ViewChild('videoContainer')
  videoContainer!: ElementRef<HTMLVideoElement>;
  @ViewChild("message", { static: true }) message!: ElementRef<HTMLVideoElement>;
  @ViewChild('progressBar')
  progressBar!: ElementRef;
  public videoElement!: HTMLVideoElement;
  // receive video detail from utm
  @Input()
  public videoPlayerScreenDetail!: StreamVideoDetail;
  //end receive video detail from utm
  public isAudioDisabled: boolean = false;
  public videoDuriation: string ='';
  public isVideoLoaded: boolean = false;
  public videoStreamErrorMsg: string ='';
  public videoLoadingText: string = STREAM_SHORTLY_BEGAIN_TXT;
  public token:string="";
  constructor(@Inject(DOCUMENT) private document: Document, private mediaMtxWebRTC: MediaMtxWebRTCService, private mediaMtxHLS: MediaMtxHLSService, private cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['videoPlayerScreenDetail']) {
      if (changes['videoPlayerScreenDetail'].currentValue.type === 'start' || changes['videoPlayerScreenDetail'].currentValue.type === 'destroy-start') {
        this.videoUrl = changes['videoPlayerScreenDetail'].currentValue.streamURL;
        this.videoStreamId = changes['videoPlayerScreenDetail'].currentValue.id;
        this.liveStream = changes['videoPlayerScreenDetail'].currentValue.liveStream;
        if (!this.videoUrl) {
          this.videoStreamErrorMsg = FAILED_STREAM_VIDEO;
        }
        this.displayControllsOpacity = 1;
        if (this.liveStream) {
          this.watchedProgress = 100;
        }
        if(changes['videoPlayerScreenDetail'].currentValue.mediaServer){
          this.mediaServer = changes['videoPlayerScreenDetail'].currentValue.mediaServer
        }
        if(changes['videoPlayerScreenDetail'].currentValue.token){
          this.token = changes['videoPlayerScreenDetail'].currentValue.token
        }
        this.setPlaybackSpeed(DEFAULT_PLAYBACK_SPEED);
      }
    }
    if (changes['videoUrl']) {
      this.setPlaybackSpeed(DEFAULT_PLAYBACK_SPEED);
    }
  }

  ngAfterViewInit(): void {
    this.videoElement = this.video.nativeElement;
    this.videoElement.addEventListener("error", () => {
      this.isVideoLoaded = false;
    });
    //check video content is loaded
    this.videoElement.addEventListener('loadeddata', (e) => {
      if (this.videoElement.readyState >= 3) {
        this.isVideoLoaded = true;
      }
    });
    //end of check video content loaded
    this.videoContainer.nativeElement.addEventListener('mousemove', () => {
      this.displayControls();
    });
    this.document.addEventListener('fullscreenchange', () => {
      this.isFullScreen = !!document.fullscreenElement;
    });
    this.document.addEventListener('keyup', (event) => {
      if (event.code === 'Space') {
        this.playPause();
      }
      if (event.code === 'KeyM') {
        this.toggleMute();
      }
      if (event.code === 'KeyF') {
        this.toggleFullScreen();
      }
      this.displayControls();
    });
    if (this.mute) {
      this.videoElement.muted = true;
      this.isFullVolume = false;
    }
    if (this.autoplay) {
      this.videoElement.autoplay = true;
      this.videoElement.play();
      this.isPlaying = true;
      this.cdr.detectChanges();
    }
    this.videoElement.addEventListener('timeupdate', () => {
      this.watchedProgress = (this.videoElement.currentTime / this.videoElement.duration) * 100;
      if (!isNaN(this.videoElement.duration)) {
        this.videoDuriation = this.secondsToHHMMSS(this.videoElement.duration);
        const totalSecondsRemaining = this.videoElement.currentTime;
        this.durationRemaining = this.secondsToHHMMSS(totalSecondsRemaining);
      } else {
        this.videoDuriation = '00:00';
        this.durationRemaining = '00:00';
      }
      this.videoStateChange.emit({
        state: this.isPlaying ? VIDEO_STATUS.PLAYING : VIDEO_STATUS.PAUSED,
        currentTime: this.videoElement.currentTime,
        totalTime: this.videoElement.duration
      });
    });
    this.progressBar.nativeElement.addEventListener(
      'click',
      (event: MouseEvent) => {
        const progressBarEle = this.progressBar.nativeElement as HTMLElement;
        const rect = progressBarEle.getBoundingClientRect();
        const pos = (event.clientX - rect.left) / rect.width;
        this.videoElement.currentTime = pos * this.videoElement.duration;
      }
    );
    this.videoElement.addEventListener('progress', () => {
      if (!this.liveStream) {
        let range = 0;
        const bf = this.videoElement.buffered;
        const time = this.videoElement.currentTime;
        while (!(bf.start(range) <= time && time <= bf.end(range))) {
          range += 1;
        }
        const loadEndPercentage = bf.end(range) / this.videoElement.duration;
        this.loadPercentage = loadEndPercentage * 100;
      }
    });
    this.videoElement.addEventListener('waiting', (data) => {
      this.isVideoLoaded = false;
      this.isPlaying = false;
    });
    this.videoElement.addEventListener('playing', (data) => {
      this.isVideoLoaded = true;
      this.isPlaying = true;
    });
    this.videoElement.addEventListener('ended', (data) => {
      this.isPlaying = false;
    });
    this.checkLiveStreaming()
  }
  checkLiveStreaming() {
    if (this.liveStream && this.videoUrl) {
      if (this.mediaServer == MEDIA_SERVERS.MEDIAMTX) {
        this.mediaMtxHLS.init(this.video, this.message, this.videoUrl,this.token);
      }
    }
  }
  setPlaybackSpeed(speed: string) {
    this.currentPlaybackSpeed = parseFloat(speed);
    if (this.videoElement) {
      this.videoElement.playbackRate = this.currentPlaybackSpeed;
    }
  }
  displayControls() {
    this.displayControllsOpacity = 1;
    document.body.style.cursor = 'initial';
    if (this.controlsTimeout) {
      clearTimeout(this.controlsTimeout);
    }
    this.controlsTimeout = setTimeout(() => {
      this.displayControllsOpacity = this.isPlaying ? 0 : 1;
      document.body.style.cursor = 'none';
    }, 2000);
  }
  playPause() {
    if (this.videoElement.paused) {
      this.videoElement.play();
    } else {
      this.videoElement.pause();
    }
    this.isPlaying = !this.videoElement.paused;
  }
  toggleMute() {
    this.isAudioDisabled = !this.videoElement.muted;
    this.videoElement.muted = !this.videoElement.muted;
  }
  toggleFullScreen() {
    if (
      !document.fullscreenElement &&
      !(document as Document).webkitFullscreenElement
    ) {
      this.openFullscreen();
    } else {
      this.closeFullscreen();
    }
  }
  openFullscreen() {
    if (this.videoContainer.nativeElement.requestFullscreen) {
      this.videoContainer.nativeElement.requestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    if ((document as Document).exitFullscreen) {
      (document as Document).exitFullscreen();
    } else if ((document as Document).webkitExitFullscreen) {
      /* Safari */
      (document as Document).webkitExitFullscreen();
    }
  }
  rewindTime(seconds: number = REWIND_TIME) {
    this.videoElement.currentTime += seconds;
  }
  forwardTime(seconds: number = FORWARD_TIME) {
    this.videoElement.currentTime += seconds;
  }
  secondsToHHMMSS(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    let remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    remainingSeconds = remainingSeconds % 60;
    const secondsWhole = Math.floor(remainingSeconds);
    if (hours === 0) {
      return this.ensureTwoDigits(minutes) + ':' + this.ensureTwoDigits(secondsWhole);
    } else {
      return (
        this.ensureTwoDigits(hours) + ':' + this.ensureTwoDigits(minutes) + ':' + this.ensureTwoDigits(secondsWhole)
      );
    }
  }
  ensureTwoDigits(number: number) {
    return (number < 10 ? '0' : '') + number;
  }
}
