import { Component, Input } from '@angular/core';
import { DEFAULT_HSF_CONFIG } from './hsfconfig.const';
import * as _ from 'lodash';
import { StreamVideoDetail } from './interfaces/stream-video-detail.interface';
@Component({
  selector: 'fe-micro-share-components-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fe-micro-share-components';
  config: any = {
    header: { show: false },
    sidebar: { show: false },
    footer: { show: false },
  }
  public modalVideoPlayerScreenDetail!:StreamVideoDetail;
  public displayVideoStreamScreen:boolean = false;
  ngOnInit() {
    this.componentLoaded()
    this.listenChangingConfig()
    //listen from utm repo
    window.addEventListener('dronos:utm:video', (event:any) => {
      switch (event.detail.type) {
        case "destroy":
          this.destroyVideoPlayer();
          break;
        case "start":
          this.modalVideoPlayerScreenDetail = event.detail;
          this.displayVideoStreamScreen = true;
          break;
        case "destroy-start":
          this.destroyVideoPlayer();
          setTimeout(() => {
            this.modalVideoPlayerScreenDetail = event.detail;
            this.displayVideoStreamScreen = true;
          }, 1500);
          break;
        default:
            throw new Error("Invalid type");
      }
    })
    //end of listen from utm repo
  }
  destroyVideoPlayer(){
    this.modalVideoPlayerScreenDetail = {}
    this.displayVideoStreamScreen = false;
  }
  componentLoaded() {
    const customEvent = new CustomEvent('dronos:share-components:loaded')
    window.dispatchEvent(customEvent)
  }
  listenChangingConfig() {
    const root = document.documentElement
    window.addEventListener('change-config', (event: any) => {
      const incomingConfig = event.detail
      const defaultHSFConfig = _.cloneDeep(DEFAULT_HSF_CONFIG)
      const mergedConfig = _.merge(defaultHSFConfig, incomingConfig)
      this.config = incomingConfig !== null ? mergedConfig : DEFAULT_HSF_CONFIG
      this.setHeaderSidebarFooterLayout(root)
    })
  }
  setHeaderSidebarFooterLayout(root: HTMLElement) {
    setTimeout(() => {
      root.style.setProperty('--header-height', this.config.header.show ? '44px' : '0px')
      root.style.setProperty('--sidebar-width', this.config.sidebar.show ? '44px' : '0px')
      const bottomMenuEl = document.querySelector('.bottom-menu')
      root.style.setProperty('--footer-height', this.config.footer.show ? `${bottomMenuEl?.clientHeight}px` : '0px')
    })
  }
}
