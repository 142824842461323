import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service'; 
import { baseUrls } from '../shared/constants';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl();

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private http: HttpClient) {}

  uploadTicketFile(payload : any, folderName : string): Observable<any[]> {
    return this.http.post<any[]>(`${apiUrl}/data-resource/storage?resourceType=${folderName}`, payload);
  }

  postUserData(data: any){
    return this.http.post<any>(`${apiUrl}/account/contact-us/ticket`, data)
  }

  deleteFiles(fileKey: string): Observable<any> {
    const url = `${apiUrl}${baseUrls.DATA_RESOURCE}${fileKey}`;
    return this.http.delete<any>(url);
  }
}

