import { ElementRef, Injectable } from "@angular/core";
import Hls from "hls.js"
import { RETRY_PAUSE } from "../video-player/video-player.component.const";
import { STREAM_SHORTLY_BEGAIN_TXT } from "../constants/shared.const";

@Injectable({
  providedIn: "root",
})
export class MediaMtxHLSService {
  streamURL: string = "";
  defaultControls = false;
  video!: ElementRef<HTMLVideoElement>
  message!: ElementRef<HTMLElement>
  retryPause = RETRY_PAUSE;
  token:string="";
  private interceptedUrls: Set<string> = new Set();
  constructor() { }
  init(videoElement: ElementRef<HTMLVideoElement>, message: ElementRef<HTMLElement>, videoURL: string,token:string) {
    this.video = videoElement;
    this.message = message;
    this.defaultControls = this.video.nativeElement.controls;
    this.streamURL = videoURL;
    this.token = token;
    this.loadStream()
  }
  loadStream() {
    if (Hls.isSupported()) {
      const hls = new Hls({
        maxLiveSyncPlaybackRate: 1.5,
      });
      const token: string = this.token
      const interceptedUrl: Set<string> = this.interceptedUrls;
      class CustomXMLHttpRequest extends XMLHttpRequest {
        override open(method: string, url: string) {
          if (!interceptedUrl.has(url)) {
            const separator = url.includes('?') ? '&' : '?';
            url += (token) ? separator + 'jwt=' + token : '';
            interceptedUrl.add(url);
          }
          return super.open(method, url);
        }
      }
      window.XMLHttpRequest = CustomXMLHttpRequest;
      hls.on(Hls.Events.ERROR, (_evt, data) => {
        if (data.fatal) {
          hls.destroy();
          setTimeout(() => this.loadStream(), this.retryPause);
          if (data.details === 'manifestIncompatibleCodecsError') {
            this.setMessage('stream makes use of codecs which are incompatible with this browser or operative system');
          } else if (data.response && data.response.code === 404) {
            this.setMessage(STREAM_SHORTLY_BEGAIN_TXT);
          } else {
            this.setMessage(data.error + ', retrying in some seconds');
          }
        }
      });
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        let url = this.streamURL + 'index.m3u8';
        hls.loadSource(url);
      });
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.setMessage('');
        this.video.nativeElement.play();
      });
      hls.attachMedia(this.video.nativeElement);
    } else if (this.video.nativeElement.canPlayType('application/vnd.apple.mpegurl')) {
      fetch('index.m3u8')
        .then(() => {
          this.video.nativeElement.src = 'index.m3u8';
          this.video.nativeElement.play();
        });
    }
  };
  setMessage(str: string) {
    this.video.nativeElement.controls = str !== '' ? false : this.defaultControls;
    this.message.nativeElement.innerText = str;
  };
}
