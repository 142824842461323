import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-weather-table',
  templateUrl: './weather-table.component.html',
  styleUrls: ['./weather-table.component.scss'],
})
export class WeatherTableComponent implements OnChanges {
  @Input() weatherDataList: any;
  @Input() weatherFullData: any;
  @Input() weatherLabel: any;
  @Input() weatherTap: any;
  weatherHours = 3;
  weatherType: string = ''

  getHours(val: any) {
    this.weatherHours = val
    this.filterByHours()
  }

  filterByHours() {
    if (this.weatherHours === 3) {
      this.weatherDataList = this.weatherFullData.map((x: any) => ({
        ...x,
        data: x.data.filter((y: any) => this.increment3(y.hour))
      }))
    } else {
      this.weatherDataList = this.weatherFullData
    }
  }

  increment3(hour: number) {
    return hour % 3 === 0
  }

  ngOnChanges(changes: SimpleChanges): void {
    const weatherDataListChanges = changes['weatherDataList']
    if (weatherDataListChanges !== undefined) {
      if (weatherDataListChanges.currentValue !== weatherDataListChanges.previousValue) {
        this.filterByHours()
      }
    }
    this.handleWeatherType()
  }

  handleWeatherType() {
    if (this.weatherTap === 1) {
      this.weatherType = 'precipitation'
    } else if (this.weatherTap === 2) {
      this.weatherType = 'temperature'
    } else if (this.weatherTap === 3) {
      this.weatherType = 'wind'
    } else if (this.weatherTap === 4) {
      this.weatherType = 'cloud'
    }
  }
}
