<div class='contact-us-wrapper' [class.active]="isPanelOpen">
  <div class="contact-us-header">
    <h3 class="title">Submit Ticket</h3>
    <button class="btn" type="button" class="cross-btn" (click)="closeContactPanel(false)">
      <img [src]="'/images/cross.svg' | assetUrl" alt="cross image" class="cross-icon" />
    </button>
  </div>
  <form [formGroup]="ticketForm" (ngSubmit)="submitForm()" #formGroupRef="ngForm">
    <div class="form-group" class="form-field-group">
      <label for="email" class="form-label">Email address</label>
      <input type="email" class="form-control form-field" placeholder="enter email" formControlName="email" id="email"
        [attr.disabled]="true" />
    </div>
    <div class="radio-btn-wrapper">
      <div class="form-check form-check-inline">
        <input class="form-check-input" class="btn-input" type="radio" formControlName="featureType" id="feature"
          value="feature" (change)="onRadioChange($event)">
        <label class="btn-txt" for="feature">Feature Request</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" class="btn-input" type="radio" formControlName="featureType" id="bug"
          value="bug" (change)="onRadioChange($event)">
        <label class="btn-txt" for="bug">Bug Report</label>
      </div>
      <div
        *ngIf="ticketForm.controls['featureType'].invalid && (ticketForm.controls['featureType'].dirty || ticketForm.controls['featureType'].touched)"
        class="error-msg">
        Type is required.
      </div>
    </div>
    <div class="form-group" class="form-field-group">
      <label class="form-label" for="subject">Subject</label>
      <input type="text" class="form-control" class="form-field" placeholder="enter subject" formControlName="subject"
        id="subject" [(ngModel)]="ticket.subject" />
      <div
        *ngIf="ticketForm.controls['subject'].invalid &&   (ticketForm.controls['description'].dirty || ticketForm.controls['subject'].touched)"
        class="error-msg">
        Subject is required.
      </div>
    </div>
    <div class="form-group" class="form-field-group">
      <label for="desc" class="form-label">Description</label>
      <p class="form-label-txt">
        Please include details of your request so we can provide the most complete and accurate support. If relevant,
        screenshots and video recordings are highly encouraged.
      </p>
      <textarea class="form-control" class='form-field form-textarea' placeholder="enter description of your situation"
        id="desc" formControlName="description" [(ngModel)]="ticket.description"></textarea>
      <div
        *ngIf="ticketForm.controls['description'].invalid && (ticketForm.controls['description'].dirty || ticketForm.controls['description'].touched)"
        class="error-msg">
        Description is required.
      </div>
    </div>
    <div class="form-group form-field-group">
      <label for="attachment" class="form-label">Attachments <span *ngIf="uploadedFiles.length">({{ uploadedFiles.length
          }})</span></label>
      <div class="upload-area" *ngIf="hideAttachments">
        <input type="file" id="upload" class="upload-input" (change)="onFileSelected($event)" multiple />
        <div class="upload-content">
          <img [src]="'/images/attachement.svg' | assetUrl" alt="attachment image" class="icon" />
          <span>Attach up to 5 files.</span>
        </div>
      </div>
      <div class="uploaded-files">
        <div *ngFor="let file of uploadedFiles; let i = index" class="file-item">
          <img [src]="'/images/img.svg' | assetUrl" alt="img-icon" />
          <div class="file-details">
            <span class="file-formControlName">{{ file.value.name }}</span>
          </div>
          <div class="file-size">
            <span>{{ formatFileSize(file.value.size) }}</span>
          </div>
          <button class="remove-file" (click)="removeFile(file.id,i)"><img [src]="'/images/cross.svg' | assetUrl"
              alt="cross image" class="cross-icon" /></button>
        </div>
      </div>
    </div>
    <button type="submit" class="btn" class="submit-btn" [disabled]="isFileUploading">submit</button>
  </form>
</div>
<app-toast message="Unable to upload attachment(s)" state="errorState" *ngIf="showErrorToast"></app-toast>