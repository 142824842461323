import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrls } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class OpenMeteoService {

  constructor(private http: HttpClient) { }

  todayForecast(coordinate: any) {
    const lat = coordinate.lat
    const lng = coordinate.lng
    const url = `${baseUrls.FORCAST}=${lat}&longitude=${lng}&current_weather=true&daily=temperature_2m_max,temperature_2m_min,sunrise,sunset,rain_sum,precipitation_probability_max,windspeed_10m_max,windgusts_10m_max,winddirection_10m_dominant&timezone=auto&forecast_days=1`
    return this.http.get(url)
  }

  hourlyForecast(coordinate: any) {
    const precipitationParam = 'relativehumidity_2m,precipitation_probability'
    const temperatureParam = 'temperature_2m,dewpoint_2m'
    const windParam = 'windspeed_10m,winddirection_10m,windgusts_10m'
    const cloudParam = 'cloudcover,visibility'
    const hourlyParams = `${precipitationParam},${temperatureParam},${windParam},${cloudParam}`
    const lat = coordinate.lat
    const lng = coordinate.lng
    const url = `${baseUrls.FORCAST}=${lat}&longitude=${lng}&hourly=${hourlyParams}`
    return this.http.get(url)
  }
}
