<div class="navbar-container zI-5" *ngIf="headerConfig.show">
  <div
    class="col-12 nav-style d-flex justify-content-between align-items-center"
  >
    <div class="title title-wrapper">
      <ng-container *ngIf="headerConfig.children[0].show">
        <div
          class="cursor-style"
          (click)="backToHome()"
          data-bs-toggle="tooltip"
          title="Back to home"
        >
          <img
            [src]="'/header/dronos-logo-header.svg' | assetUrl"
            class="dronos-img"
            alt="aerodyne-icon"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="headerConfig.children[1].show">
        <div class="d-flex flex-row align-items-center mx-2 tooltip-container">
          <app-tooltip-with-icon
            *ngIf="
              showSiteInfoIcon() &&
              siteInfo?.siteName?.length &&
              siteInfo?.captureDateAndTime
            "
          >
            <div slot="text"></div>
            <div slot="content">
              <div
                class="d-flex flex-column justify-content-start align-items-start tooltip-box"
              >
                <span class="site-tooltip-id"
                  >{{ siteInfo?.externalClientSiteId ?? "A11D06HS" }}
                </span>
                <span class="site-tooltip-name">{{
                  siteInfo?.siteName ?? "KANGAROO HILL"
                }}</span>
                <div
                  class="d-flex justify-content-between align-items-start elevation-box"
                >
                  <div class="d-flex flex-column align-items-start">
                    <span class="elevation-value">{{
                      siteInfo?.siteLatitude ?? "-20.6565"
                    }}</span>
                    <span class="elevation-label">Latitude</span>
                  </div>
                  <div class="d-flex flex-column align-items-start">
                    <span class="elevation-value">{{
                      siteInfo?.siteLongitude ?? "-20.6565"
                    }}</span>
                    <span class="elevation-label">Longitude</span>
                  </div>
                  <div class="d-flex flex-column align-items-start">
                    <span class="elevation-value">{{
                      siteInfo?.industryType ?? "TELCO"
                    }}</span>
                    <span class="elevation-label">Asset type</span>
                  </div>
                </div>
                <div class="date-time-section mt-4">
                  Data capture date:
                  <span class="date-value">
                    {{
                      momemt(siteInfo.captureDateAndTime).format("DD-MM-YYYY")
                    }}
                  </span>
                </div>
                <div class="date-time-section mt-4">
                  Data capture Time:
                  <span class="date-value">
                    {{ momemt(siteInfo.captureDateAndTime).format("hh:mm A") }}
                    {{
                      momemt
                        .tz(siteInfo.captureDateAndTime, userTimeZone)
                        .zoneName()
                    }}
                  </span>
                </div>
              </div>
            </div>
          </app-tooltip-with-icon>
        </div>
      </ng-container>
    </div>
    <div class="d-flex align-items-center">
      <div *PermissionGuard="[USER_PERMISSIONS.INVENTORY_SITE_SEARCH]">
        <div class="search-position">
          <div
            id="search-site"
            class="site-input-control"
            [ngClass]="searchInput?.length ? 'search-opacity' : ''"
            (mouseleave)="onMouseLeave()"
          >
            <img
              [src]="'/header/search-mission.svg' | assetUrl"
              id="search-icon"
              class="search-icon cursor-style"
              alt="search-icon"
            />
            <input
              type="text"
              class="custom-site-input"
              [placeholder]="'Search for Sites'"
              (ngModelChange)="searchByInput($event)"
              (keypress)="searchByEnter($event)"
              id="search-input"
              [(ngModel)]="searchInput"
            />
          </div>
          <div
            class="search-options"
            *ngIf="showSearchDropdown"
            id="search-result"
          >
            <div class="site-details-label">SITES</div>
            <ng-container *ngIf="isNoResult">
              <div class="mt-1">
                <span class="result-text"> No results</span>
              </div>
            </ng-container>
            <ng-container #elseBlock>
              <div
                *ngFor="let item of resultSearch; let idx = index"
                class="d-flex mt-1"
              >
                <i-feather
                  name="map-pin"
                  size="15"
                  class="map-icon arrow-icons-size"
                />
                <span
                  class="search-result cursor-style"
                  (click)="navigateToSearchResult(item._id, item.siteName)"
                >
                  {{ item.siteName }} - {{ item.externalClientSiteId }}
                </span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="headerConfig.children[3].show" class="notification-wrapper">
        <img
          [src]="'/header/notification.svg' | assetUrl"
          class="notification-icon"
          alt="notification-icon"
          (click)="toggleDropdown()"
        />
        @if( showNotification ) {
        <div class="notification-menu">
          <span class="header">Notifications</span>
          <div [ngClass]="{ 'd-none': !showNotification }" class="w-100">
            <div
              class="d-flex flex-column w-100 notification-list"
              #dropdownElement
              (scroll)="setupScrollListener($event)"
            >
              @for(record of notifications.records; track record) {
              <div class="notification-item d-flex g-8 justify-content-start">
                <img
                  [src]="'/header/aerodyne.png' | assetUrl"
                  class="aerodyne-img"
                  alt="aerodyne-logo"
                />
                <div class="d-flex flex-column g-8 w-100">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span class="title">
                      {{ record.title }}
                    </span>
                    @if(isReportLoading( record?.metadata?.dataResourceIds?.[0]
                    ) ){
                    <span class="loader"></span>
                    }@else if(record?.metadata?.dataResourceIds?.[0]){
                    <img
                      [src]="'/header/download.svg' | assetUrl"
                      class="aerodyne-img download-icon"
                      alt="aerodyne-logo"
                      (click)="downloadReport(record?.metadata?.dataResourceIds?.[0])"
                    />
                    }
                  </div>
                  <span class="content">
                    {{ record.content }}
                  </span>
                  <span class="time-passed">
                    {{ helperService.calculateTimePassed(record.createdAt) }}
                  </span>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
        }
      </div>
      <div *ngIf="headerConfig.children[2].show" class="border mb-0"></div>
      <div *ngIf="headerConfig.children[4].show" class="dropdown profile">
        <div
          class="d-flex align-items-center profile-dropdown"
          (click)="showProfileDropdown = !showProfileDropdown"
          id="profile-dropdown"
        >
          <!-- load dont have profile picture -->
          <div
            *ngIf="isLoadProfilePic || userData.profilePicture === ''"
            class="profile-img-container"
          >
            <ng-container *ngIf="userData.firstName !== ''; else defaultIcon">
              <span class="initial-letter">{{ getInitialLetter() }}</span>
            </ng-container>
            <ng-template #defaultIcon>
              <i-feather
                name="user"
                size="20"
                class="color-icon icon-size"
              ></i-feather>
            </ng-template>
          </div>
          <div *ngIf="!isLoadProfilePic && userData.profilePicture">
            <img
              [src]="userData.profilePicture"
              class="rounded-circle profile-img-container"
              alt="Avatar"
            />
          </div>
          <i-feather
            name="chevron-down"
            size="15"
            class="d-flex align-items-center chevron-icon arrow-icons-size"
          />
        </div>
        <div class="dropdown-menu show" *ngIf="showProfileDropdown">
          <div class="d-flex profile-padding align-items-center">
            <div *ngIf="isLoadProfilePic || userData.profilePicture === ''">
              <i-feather name="user" size="20" class="color-icon icon-size" />
            </div>
            <div *ngIf="!isLoadProfilePic && userData.profilePicture">
              <img
                [src]="userData.profilePicture"
                class="rounded-circle profile-img-container"
                alt="Avatar"
              />
            </div>
            <div class="name-username-margin w-100">
              <div class="profile-text">
                {{
                  userData.email
                    ? userData.email.length >= 25
                      ? userData.email.slice(0, 25) + "..."
                      : userData.email
                    : "No email"
                }}
              </div>
              <div class="profile-name">
                {{
                  userData.username
                    ? userData.username.length >= 25
                      ? userData.username.slice(0, 25) + "..."
                      : userData.username
                    : "No username"
                }}
              </div>
            </div>
          </div>

          <div class="workspaces-list">
            <span class="workspace-title">Workspace</span>
            <div
              *ngFor="let data of userData.member; let i = index"
              class="workspace-entity"
              (click)="switchWorkspace(data.workspace._id)"
            >
              <img
                [src]="'/header/round-check.svg' | assetUrl"
                alt="tick-icon"
                *ngIf="data.workspace._id == userData.activeWorkspaceId"
              />
              <div class="d-flex align-items-center g-8">
                <img
                  [src]="
                    data.workspace.logo
                      ? data.workspace.logo
                      : ('/header/profile-avatar.svg' | assetUrl)
                  "
                  alt="workspace-logo"
                  class="rounded-circle profile-img-container"
                />
                <span class="workspace-name">{{ data.workspace.name }}</span>
              </div>
            </div>
          </div>

          <div *ngFor="let data of navbarList; let i = index">
            <ng-container *ngIf="data.id === 'Workspace'"> </ng-container>
            <ng-container>
              <div
                class="d-flex flex-row profile-item cursor-style"
                (click)="callFunc(data.id)"
              >
                <img
                  [src]="getImgUrl(data.img)"
                  class="icon-width"
                  alt="profile-avatar"
                />
                <span class="profile-text"> {{ data.name }} </span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
@if (showNotification) {
<div class="dropdown-backdrop" (click)="showNotification = false"></div>
}
