import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { VideoPlayerComponent } from '../../video-player/video-player.component';
import { StreamVideoDetail } from '../../interfaces/stream-video-detail.interface';
@Component({
  selector: 'shared-components-modal-video-galary',
  standalone: true,
  imports: [VideoPlayerComponent],
  templateUrl: './modal-video-galary.component.html',
  styleUrl: './modal-video-galary.component.scss'
})
export class ModalVideoGalaryComponent {
  //video player screen detail
  @Input() public modalVideoPlayerScreenDetail!: StreamVideoDetail;
  //end of video player screen detail
  public initVideoStream:boolean = false;
  public videoPlayerScreenDetail!:StreamVideoDetail;
  ngOnChanges(changes: SimpleChanges): void{
    if(changes['modalVideoPlayerScreenDetail']){
      this.initVideoStream = false;
      this.videoPlayerScreenDetail= {
        type:changes['modalVideoPlayerScreenDetail'].currentValue.type,
        id:changes['modalVideoPlayerScreenDetail'].currentValue.id,
        streamURL:changes['modalVideoPlayerScreenDetail'].currentValue.streamURL,
        liveStream:changes['modalVideoPlayerScreenDetail'].currentValue.liveStream,
        mediaServer:changes['modalVideoPlayerScreenDetail'].currentValue.mediaServer,
        token:changes['modalVideoPlayerScreenDetail'].currentValue.token
      }
      this.initVideoStream = true;
    }
  }
  closeVideo(){
    window.dispatchEvent(new CustomEvent('dronos:utm:video-payer', { detail: {clearVideo:true} }));
  }
}
