import { Injectable, OnDestroy } from '@angular/core';
import { state } from '@app/utility';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { EnvironmentService } from '../environment.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Notification } from 'src/app/interfaces';
import { HttpClient } from '@angular/common/http';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl();

@Injectable({
  providedIn: 'root',
})
export class SseService implements OnDestroy {
  constructor(private httpClient: HttpClient) {}
  notifications: Notification = {
    records: [],
    meta: {
      nextCursor: '',
      prevCursor: '',
    },
  };
  fetchingNotifications: boolean = false;

  public _simulatedNotifications = new BehaviorSubject<any>(null);
  abortController = new AbortController();
  isFirstTime = true;

  createEventSource(): any {
    class RetriableError extends Error {}
    class FatalError extends Error {}

    fetchEventSource(
      `${apiUrl}/notification/workspace-notification/emitter?userId=${
        state.getUserInfo()._id
      }`,
      {
        headers: {
          Authorization: `Bearer ${state.getDronosToken()?.value}`,
          WorkspaceId: state.getUserInfo()?.activeWorkspaceId,
        },
        signal: this.abortController.signal,
        async onopen(response) {
          if (response.ok) {
            return;
          } else if (
            response.status >= 400 &&
            response.status < 500 &&
            response.status !== 429
          ) {
            throw new FatalError();
          } else {
            throw new RetriableError();
          }
        },
        onmessage: (msg) => {
          if (msg.event === 'FatalError') {
            throw new FatalError(msg.data);
          }
          if (msg.data && typeof JSON.parse(msg.data) == 'object') {
            if (this.isFirstTime) {
              this.notifications = JSON.parse(msg.data);
            }
            if (!this.isFirstTime && !JSON.parse(msg.data)?.records?.length) {
              const newNotification = JSON.parse(msg.data);
              this.notifications = {
                ...this.notifications,
                records: [newNotification, ...this.notifications.records],
              };
            }
            this._simulatedNotifications.next(this.notifications);
            this.isFirstTime = false;
          }
        },
        onclose() {
          throw new RetriableError();
        },
        onerror(err) {
          if (err instanceof FatalError) {
            throw err;
          }
        },
      }
    );
  }

  ngOnDestroy(): void {
    this.abortController.abort();
  }

  async fetchNextNotifications(nextCursor: string) {
    if (this.fetchingNotifications) {
      return;
    }
    this.fetchingNotifications = true;
    try {
      const response = await lastValueFrom(
        this.httpClient
          .get<any>(
            `${apiUrl}/notification/workspace-notification?userId=${
              state.getUserInfo()._id
            }&nextCursor=${nextCursor}`,
            {
              headers: {
                WorkspaceId: state.getUserInfo()?.activeWorkspaceId,
              },
            }
          )
          .pipe()
      );
      if (response) {
        this.notifications.meta.nextCursor = response.meta.nextCursor;
        this.notifications.records = [
          ...this.notifications.records,
          ...response.records,
        ];
        this._simulatedNotifications.next(this.notifications);
        this.fetchingNotifications = false;
      }
    } catch (err) {
      this.fetchingNotifications = false;
    }
  }
}
