<div id="bottomMenu" *ngIf="footerConfig.show">
  <div class="bottom-menu">
    <div class="bottom-menu-left">
      <div class="weather-box" (click)="onWeatherTap(1)" [ngClass]="{ 'weather-tap-active': weatherTap == 1 }"
        [ngStyle]="footerConfig.children[0].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/image.png' | assetUrl" alt="precipitation icon" />
        <span>Precipitation {{ dailyWeatherForecast.precipitation }}</span>
      </div>
      <div class="weather-box" (click)="onWeatherTap(2)" [ngClass]="{ 'weather-tap-active': weatherTap == 2 }"
        [ngStyle]="footerConfig.children[1].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/sun.png' | assetUrl" alt="sun icon" />
        <span>{{ dailyWeatherForecast.current.temperature }}°C |
          {{ (dailyWeatherForecast.current.temperature * 1.8 + 32).toString() | slice : 0 : 4 }}°F
        </span>
      </div>
      <div class="weather-box" (click)="onWeatherTap(3)" [ngClass]="{ 'weather-tap-active': weatherTap == 3 }"
        [ngStyle]="footerConfig.children[2].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/wind.png' | assetUrl" alt="wind icon" />
        <span> Wind: {{ dailyWeatherForecast.current.windSpeed + "km/h" }}</span>
      </div>
      <div class="weather-box" (click)="onWeatherTap(4)" [ngClass]="{ 'weather-tap-active': weatherTap == 4 }"
        [ngStyle]="footerConfig.children[3].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/cloud-fog.png' | assetUrl" alt="cloud-fog icon" />
        <span>Cloud Cover</span>
      </div>
    </div>
    <div class="bottom-menu-right">
      <div class="weather-box" [ngStyle]="footerConfig.children[5].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/location.png' | assetUrl" alt="location icon" />
        <span id="location">27.2046° N, 77.4977° E</span>
      </div>
      <div class="weather-box" [ngStyle]="footerConfig.children[6].show ? {'display': 'block'} : {'display': 'none'}">
        <span id="locationName">No location recorded</span>
      </div>
      <div [ngStyle]="footerConfig.children[7].show ? {'display': 'flex'} : {'display': 'none'}">
        <div class="weather-box">
          <img [src]="'/footer/Subtract.png' | assetUrl" alt="subtract icon" />
        </div>
        <div class="weather-box"><span id="map-scale">30</span><span>KM</span></div>
      </div>
      <div class="weather-box" [ngStyle]="footerConfig.children[8].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/airspace-informatics.svg' | assetUrl" alt="airspace-informatics icon" />
        <span id="location">Airspace Informatics</span>
      </div>
    </div>
  </div>
  <div id="weather-info" class="weather-info" *ngIf="weatherTap != 0">
    <div class="weather-info-left">
      <ng-container>
        <app-weather-table [weatherDataList]="hourlyWeatherForecast" [weatherLabel]="weatherLabel"
          [weatherFullData]="hourlyWeatherForecast" [weatherLabel]="weatherLabel"
          [weatherTap]="weatherTap"></app-weather-table>
      </ng-container>
    </div>
    <div class="weather-info-right">
      <div class="weather-summary">
        <div class="weather-summary-head">
          <div class="weather-summary-head-left">
            <div>{{ todayDate }}</div>
            <div>Daily Weather Forecasting</div>
          </div>
          <div class="weather-summary-head-right">
            <div>H:</div>
            <div>
              {{ dailyWeatherForecast.temperature.max }}<span>{{ dailyWeatherForecast.temperature.unit }}/</span>
            </div>
            <div>L:</div>
            <div>
              {{ dailyWeatherForecast.temperature.min }}<span>{{ dailyWeatherForecast.temperature.unit }}</span>
            </div>
          </div>
        </div>
        <div class="weather-summary-content">
          <div class="weather-summary-content-box">
            <div class="weather-summary-content-box-top">
              <img [src]="'/master-weather/weather-summary/sun-fog.svg' | assetUrl" alt="sun-fog icon" />
              <p>SUNRISE</p>
            </div>
            <p class="weather-summary-content-box-middle">
              {{ dailyWeatherForecast.sunrise.hour12 | uppercase }}
            </p>
            <p class="weather-summary-content-box-bottom">{{ dailyWeatherForecast.sunrise.hour24 | uppercase }} Hours
            </p>
          </div>
          <div class="weather-summary-content-box">
            <div class="weather-summary-content-box-top">
              <img [src]="'/master-weather/weather-summary/drop.svg' | assetUrl" alt="droplet icon" />
              <p>RAINFALL</p>
            </div>
            <p class="weather-summary-content-box-middle">{{ dailyWeatherForecast.rainFall }}</p>
            <p class="weather-summary-content-box-bottom">Light Rain</p>
          </div>
          <div class="weather-summary-content-box">
            <div class="weather-summary-content-box-top">
              <img [src]="'/master-weather/weather-summary/wind-power.svg' | assetUrl" alt="wind-power icon" />
              <p>WIND</p>
            </div>
            <p class="weather-summary-content-box-middle">{{ dailyWeatherForecast.wind | uppercase }}</p>
            <p class="weather-summary-content-box-bottom">To the {{ dailyWeatherForecast.windDirection }}</p>
          </div>
          <div class="weather-summary-content-box">
            <div class="weather-summary-content-box-top">
              <img [src]="'/master-weather/weather-summary/sun-fog.svg' | assetUrl" alt="sun-fog icon" />
              <p>SUNSET</p>
            </div>
            <p class="weather-summary-content-box-middle">
              {{ dailyWeatherForecast.sunset.hour12 | uppercase }}
            </p>
            <p class="weather-summary-content-box-bottom">{{ dailyWeatherForecast.sunset.hour24 | uppercase }} Hours</p>
          </div>
          <div class="weather-summary-content-box">
            <div class="weather-summary-content-box-top">
              <img [src]="'/master-weather/weather-summary/drop.svg' | assetUrl" alt="droplet icon" />
              <p>PRECIPITATION</p>
            </div>
            <p class="weather-summary-content-box-middle">{{ dailyWeatherForecast.precipitation }}</p>
            <p class="weather-summary-content-box-bottom">Rain Probability</p>
          </div>
          <div class="weather-summary-content-box">
            <div class="weather-summary-content-box-top">
              <img [src]="'/master-weather/weather-summary/wind.svg' | assetUrl" alt="wind icon" />
              <p>GUSTS</p>
            </div>
            <p class="weather-summary-content-box-middle">
              {{ dailyWeatherForecast.gust | uppercase }}
            </p>
            <p class="weather-summary-content-box-bottom">Moderate</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="btn" class='contact-btn-wrapper' class="help-icon" (click)="toggleContactPanel(true)">
    <img [src]="'/images/help.svg' | assetUrl" alt="help-icon" />
  </button>
</div>
<app-ticket-form (toggleContactPanel)="toggleContactPanel($event)" (onContactFormSubmit)="onContactFormSubmit()"
  *ngIf="isContactPanelOpen" [isPanelOpen]="isContactPanelOpen"></app-ticket-form>
<app-ticket-form-toast [isTicketToastOpen]="formToast"></app-ticket-form-toast>