<div class="mission-main" (click)="UpdateshowMissionComponent.emit(false)">
  <div class="main-container">
    <div class="custom-folder">
      <div class="project-container">
        <img [src]="folderImage | assetUrl" alt="Folder Image" />
      </div>
      <h4 class="folder-title">Project</h4>
    </div>
    <div class="custom-folder">
      <div class="project-container">
        <img [src]="chartImage | assetUrl" alt="Chart Image" />
      </div>
      <h4 class="folder-title">chart</h4>
    </div>
    <div class="custom-folder">
      <div class="project-container">
        <img [src]="personImage | assetUrl" alt="Person Image" />
      </div>
      <h4 class="folder-title">User</h4>
    </div>
    <div class="custom-folder">
      <div class="project-container">
        <img [src]="mailImage | assetUrl" alt="Mail Image" />
      </div>
      <h4 class="folder-title w-86">Permit Application</h4>
    </div>
    <div class="custom-folder">
      <div class="project-container">
        <img [src]="listImage | assetUrl" alt="List Image" />
      </div>
      <h4 class="folder-title">Label</h4>
    </div>
    <div class="custom-folder">
      <div class="project-container">
        <img [src]="settingImage | assetUrl" alt="Setting Image" />
      </div>
      <h4 class="folder-title">Inventory</h4>
    </div>
  </div>
</div>