import { ElementRef, Injectable } from "@angular/core";
import { RETRY_PAUSE } from "../video-player/video-player.component.const";
interface OfferData {
  iceUfrag: string;
  icePwd: string;
  medias: string[];
}

@Injectable({
  providedIn: "root",
})
export class MediaMtxWebRTCService {
  streamURL = "";
  pc!: RTCPeerConnection;
  restartTimeout!: ReturnType<typeof setTimeout>;
  sessionUrl = "";
  offerData!: OfferData;
  queuedCandidates: RTCIceCandidate[] = [];
  defaultControls = false;
  video!: ElementRef<HTMLVideoElement>
  message!: ElementRef<HTMLElement>
  retryPause = RETRY_PAUSE;

  init(videoElement: ElementRef<HTMLVideoElement>, message: ElementRef<HTMLElement>, streamURL: string) {
    this.video = videoElement;
    this.message = message;
    this.streamURL = streamURL;
    this.defaultControls = this.video.nativeElement.controls;
    this.requestICEServers();
  }
  requestICEServers() {
    fetch(new URL("whep", this.streamURL), {
      method: "OPTIONS",
    })
      .then((res) => {
        //need to config auth there once we set auth
        this.pc = new RTCPeerConnection();
        const direction = "sendrecv";
        this.pc.addTransceiver("video", { direction });
        this.pc.addTransceiver("audio", { direction });
        this.pc.onicecandidate = (evt) => this.onLocalCandidate(evt);
        this.pc.oniceconnectionstatechange = () => this.onConnectionState();
        this.pc.ontrack = (evt) => this.onTrack(evt);
        this.createOffer();
      })
      .catch((err) => {
        this.onError(err.toString());
      });
  }
  onConnectionState = () => {
    if (!this.restartTimeout && this.pc.iceConnectionState === 'disconnected') {
      this.onError('peer connection disconnected');
    }
  };
  onTrack(evt: RTCTrackEvent) {
    this.setMessage('');
    this.video.nativeElement.srcObject = evt.streams[0];
  };
  createOffer() {
    this.pc.createOffer().then((offer: RTCSessionDescriptionInit) => {
      this.editOffer(offer);
      this.offerData = this.parseOffer(offer?.sdp ?? "");
      this.pc.setLocalDescription(offer);
      this.sendOffer(offer);
    });
  }
  sendOffer(offer: RTCSessionDescriptionInit) {
    fetch(new URL("whep", this.streamURL), {
      method: "POST",
      headers: {
        "Content-Type": "application/sdp",
      },
      body: offer.sdp,
    })
      .then((res) => {
        switch (res.status) {
          case 201:
            break;
          case 404:
            throw new Error("stream not found");
          default:
            throw new Error(`bad status code ${res.status}`);
        }
        const location = res.headers.get('location')
        this.sessionUrl = new URL(location ? location : "", this.streamURL).toString();
        return res.text();
      })
      .then((sdp) => this.onRemoteAnswer(sdp))
      .catch((err) => {
        this.onError(err.toString());
      });
  }
  onRemoteAnswer(sdp: string) {
    if (!this.restartTimeout) {
      this.pc.setRemoteDescription(new RTCSessionDescription({
        type: 'answer',
        sdp,
      }));
      if (this.queuedCandidates.length !== 0) {
        this.sendLocalCandidates(this.queuedCandidates);
        this.queuedCandidates = [];
      }
    }
  };
  parseOffer(offer: string) {
    const ret: OfferData = {
      iceUfrag: "",
      icePwd: "",
      medias: [],
    };
    for (const line of offer.split("\r\n")) {
      if (line.startsWith("m=")) {
        ret.medias.push(line.slice("m=".length));
      } else if (ret.iceUfrag === "" && line.startsWith("a=ice-ufrag:")) {
        ret.iceUfrag = line.slice("a=ice-ufrag:".length);
      } else if (ret.icePwd === "" && line.startsWith("a=ice-pwd:")) {
        ret.icePwd = line.slice("a=ice-pwd:".length);
      }
    }
    return ret;
  }
  editOffer(offer: RTCSessionDescriptionInit) {
    const sections: string[] = offer?.sdp ? offer.sdp.split("m=") : [];
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (section.startsWith("audio")) {
        sections[i] = this.enableStereoOpus(section);
      }
    }
    offer.sdp = sections.join("m=");
  }
  onLocalCandidate(evt: RTCPeerConnectionIceEvent) {
    if (!this.restartTimeout && evt.candidate) {
      if (this.sessionUrl === "") {
        this.queuedCandidates.push(evt.candidate);
      } else {
        this.sendLocalCandidates([evt.candidate]);
      }
    }
  }
  sendLocalCandidates(candidates: any[]) {
    fetch(this.sessionUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/trickle-ice-sdpfrag",
        "If-Match": "*",
      },
      body: this.generateSdpFragment(this.offerData, candidates),
    })
      .then((res) => {
        switch (res.status) {
          case 204:
            break;
          case 404:
            throw new Error("stream not found");
          default:
            throw new Error(`bad status code ${res.status}`);
        }
      })
      .catch((err) => {
        this.onError(err.toString());
      });
  }
  generateSdpFragment(
    od: OfferData,
    candidates: any[]
  ) {
    const candidatesByMedia: { [mid: string]: any[] } = {};
    for (const candidate of candidates) {
      const mid = candidate.sdpMLineIndex;
      if (candidatesByMedia[mid] === undefined) {
        candidatesByMedia[mid] = [];
      }
      candidatesByMedia[mid].push(candidate);
    }
    // Add check for iceUfrag before accessing
    if (od.iceUfrag) {
      let frag =
        "a=ice-ufrag:" +
        od.iceUfrag +
        "\r\n" +
        "a=ice-pwd:" +
        od.icePwd +
        "\r\n";
      let mid = 0;
      for (const media of od.medias) {
        if (candidatesByMedia[mid] !== undefined) {
          frag += "m=" + media + "\r\n" + "a=mid:" + mid + "\r\n";

          for (const candidate of candidatesByMedia[mid]) {
            frag += "a=" + candidate.candidate + "\r\n";
          }
        }
        mid++;
      }
      return frag;
    } else {
      // Handle case where iceUfrag doesn't exist
      return "";
    }
  }
  setMessage = (str: string) => {
    this.video.nativeElement.controls = str === '' ? false : this.defaultControls;
    this.message.nativeElement.innerText = str;
  };
  onError(err: string) {
    if (this.restartTimeout) {
      this.setMessage(err + ', retrying in some seconds');
      this.pc.close();
      this.restartTimeout = setTimeout(() => {
        clearTimeout(this.restartTimeout);
        this.requestICEServers();
      }, this.retryPause);
      if (this.sessionUrl) {
        fetch(this.sessionUrl, {
          method: 'DELETE',
        });
      }
      this.sessionUrl = '';
      this.queuedCandidates = [];
    }
  };
  enableStereoOpus(section: string) {
    let opusPayloadFormat = '';
    let lines = section.split('\r\n');
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith('a=rtpmap:') && lines[i].toLowerCase().includes('opus/')) {
        opusPayloadFormat = lines[i].slice('a=rtpmap:'.length).split(' ')[0];
        break;
      }
    }
    if (opusPayloadFormat === '') {
      return section;
    }
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith('a=fmtp:' + opusPayloadFormat + ' ')) {
        if (!lines[i].includes('stereo')) {
          lines[i] += ';stereo=1';
        }
        if (!lines[i].includes('sprop-stereo')) {
          lines[i] += ';sprop-stereo=1';
        }
      }
    }
    return lines.join('\r\n');
  };
}
