import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { state } from '@app/utility';
import { lastValueFrom } from 'rxjs';
import * as singleSpa from 'single-spa';
import { assetUrl } from 'src/single-spa/asset-url';
import {
  ISiteListResponse,
  IUserInfo,
  Notification,
} from '../interfaces/api-response.interface';
import { InventoryService } from '../services/inventory.service';
import { UserService } from '../services/user.service';
import * as moment from 'moment-timezone';
import { USER_PERMISSIONS } from '../user-permissions/user-permission.const';
import { Router, NavigationEnd } from '@angular/router';
import { SseService } from '../services/sse/sse.service';
import { DataResourceService } from '../services/data-resource/data-resource.service';
import { HelperService } from '../services/helper/helper.service';
import { LoadingService } from '../services/loading/loading.service';
@Component({
  selector: 'fe-micro-share-components-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() headerConfig: any;
  constructor(
    private userService: UserService,
    private inventoryService: InventoryService,
    private router: Router,
    private sseService: SseService,
    private dataResourceService: DataResourceService,
    public helperService: HelperService,
    private loadingService: LoadingService
  ) {}
  showMissionComponent: boolean = false;
  USER_PERMISSIONS = USER_PERMISSIONS;
  searchInput: any = '';
  momemt: any = moment;
  hovering: Boolean = false;
  debounceTimeout: any = null;
  siteInfo: any = null;
  currentView: string = 'LAUNCHPAD';
  showSearchDropdown: Boolean = false;
  isNoResult: Boolean = false;
  isCollapseSettings: Boolean = false;
  isResourceLoading: { resourceId: string; loading: boolean } = {
    resourceId: '',
    loading: false,
  };
  userData: IUserInfo = {
    accountLinks: [],
    createdAt: '',
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    member: [],
    profilePicture: '',
    pilotInformation: [],
    socialLinks: [],
    updatedAt: '',
    userCertificate: [],
    username: '',
    verified: false,
    activeWorkspaceId: '',
    _id: '',
  };
  showProfileDropdown: Boolean = false;
  navbarList: any = [
    { name: 'My Profile', id: 'profile', img: 'user-square' },
    { name: 'Workspace', id: 'Workspace', img: 'setting' },
    { name: 'Logout', id: 'logout', img: 'logout' },
  ];
  resultSearch: any = [];
  userTimeZone: string = '';
  isLoadProfilePic: boolean = false;
  isDropdownOpen: boolean = false;
  showNotification = false;
  notifications: Notification = {
    records: [],
    meta: {
      nextCursor: '',
      prevCursor: '',
    },
  };

  @ViewChild('dropdownElement') dropdownElement!: ElementRef;

  ngOnInit(): void {
    this.sseService.createEventSource();
    window.onclick = (event) => {
      let searchInput = document.getElementById('search-input');
      let searchResult = document.getElementById('search-result');
      let dropdownButton = document.getElementById('dropdown-button');
      if (event.target !== searchResult && event.target !== searchInput) {
        this.showSearchDropdown = false;
      }
      if (!dropdownButton?.contains(event.target as Node)) {
        this.isDropdownOpen = false;
      }
    };
    this.currentViewUpdate();
    var dropdowns = document.querySelectorAll('.prevent-close');
    dropdowns.forEach(function (dropdown) {
      dropdown.addEventListener('click', function (event) {
        event.stopPropagation();
      });
    });
    this.getSiteInfo();
    const isUserLoggedIn = state.getDronosToken();
    if (isUserLoggedIn) {
      this.getProfile()
        .then(() => {
          this.getProfileImage();
          this.getWorkspaceLogos();
        })
        .catch(() => {});
    }
    window.addEventListener('dronos:create-mission:closeMission', () => {
      this.searchInput = '';
      this.closeSearch();
    });
    window.addEventListener('dronos:workspace-logo-update', (event: any) => {
      this.userData.member.forEach((member: any) => {
        if (member.workspace._id === event.detail.workspaceId) {
          member.workspace.logo = event.detail.logoUrl;
        }
      });
    });
    window.addEventListener('dronos:profile-picture-change', (event: any) => {
      this.userData = {
        ...this.userData,
        profilePicture: event.detail.url,
      };
    });
    this.sseService._simulatedNotifications.subscribe((payload) => {
      this.notifications = { ...payload };
    });

    this.loadingService.loading$.subscribe((loading) => {
      this.isResourceLoading.loading = loading;
    });
  }

  setupScrollListener(event: Event): void {
    const element = event.target as HTMLElement;
    const scrollDiff =
      element.scrollHeight - (element.scrollTop + element.clientHeight);
    if (scrollDiff < 1) {
      const nextCursor = this.notifications.meta.nextCursor;
      if (nextCursor) {
        this.sseService.fetchNextNotifications(nextCursor);
      }
    }
  }

  UpdateShowMission(condition: boolean) {
    this.showMissionComponent = condition;
  }
  toggleMissionComponent() {
    this.showMissionComponent = !this.showMissionComponent;
  }
  async getSiteInfo() {
    window.addEventListener('dronos:canvas:tooltipInfo', async (event: any) => {
      if (event.detail?.site_info) {
        this.siteInfo = {
          ...this.siteInfo,
          externalClientSiteId: event.detail.site_info?.externalClientSiteId,
          siteLatitude: event.detail.site_info?.siteLatitude,
          siteLongitude: event.detail.site_info?.siteLongitude,
          siteName: event.detail.site_info?.siteName,
          industryType: event.detail.site_info?.industryType,
        };
      }
      if (event.detail?.mission_info) {
        if (event.detail.mission_info?.records?.length) {
          this.siteInfo = {
            ...this.siteInfo,
            captureDateAndTime:
              event.detail.mission_info.records[0]?.startDate ??
              new Date().toISOString(),
          };
        } else {
          this.siteInfo = {
            ...this.siteInfo,
            captureDateAndTime:
              event.detail.mission_info?.startDate ?? new Date().toISOString(),
          };
        }
      }
      this.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.currentView = this.siteInfo?.siteName;
    });
  }

  showSiteInfoIcon() {
    return window.location.pathname.startsWith('/canvas');
  }

  async getProfile() {
    const response: IUserInfo = await lastValueFrom(
      this.userService.getUserProfile()
    );
    this.userData = response;
    localStorage.setItem('user-data', JSON.stringify(response));
  }

  getImgUrl(img: any) {
    return assetUrl('/header/' + img + '.svg');
  }

  async callFunc(data: any) {
    if (data === 'logout') {
      await lastValueFrom(this.userService.logout())
        .then((response) => {
          if (response?.status === 201) {
            state.destroyDronosToken();
            window.location.href = '/';
          }
        })
        .catch((error) => {
          state.destroyDronosToken();
          window.location.href = '/';
        });
    } else if (data === 'profile') {
      this.navigateToAccount();
    } else {
      this.navigateToCommon();
    }
  }

  backToHome() {
    if (!this.headerConfig.children[0]?.disabled) {
      singleSpa.navigateToUrl('/');
      this.currentView = 'LAUNCHPAD';
    }
  }

  onMouseLeave() {
    if (this.searchInput.length === 0) {
      this.closeSearch();
    }
  }

  closeSearch() {
    this.showSearchDropdown = false;
    setTimeout(() => {
      this.hovering = false;
    }, 300);
  }

  searchByEnter(e: any) {
    if (e.keyCode === 13 && e.target.value.length !== 0) {
      this.searchSites(e.target.value);
    }
    if (e.key === 'Backspace') {
      this.hovering = false;
    }
  }

  searchByInput(search: any) {
    clearTimeout(this.debounceTimeout);
    if (search.length > 0) {
      this.debounceTimeout = setTimeout(() => {
        this.searchSites(search);
      }, 500);
    } else {
      this.closeSearch();
    }
  }

  navigateToSearchResult(siteId: any, siteName: any) {
    this.searchInput = siteName;
    const customEvent = new CustomEvent('dronos:utm:missionFloating', {
      detail: { site_id: siteId },
    });
    window.dispatchEvent(customEvent);
    this.showSearchDropdown = false;
  }

  async searchSites(searchedText: string) {
    try {
      const response: ISiteListResponse = await lastValueFrom(
        this.inventoryService.searchSitebyName(searchedText)
      );
      const allRecords = response.records;
      this.resultSearch = allRecords;
      if (allRecords.length === 0) {
        this.isNoResult = true;
      } else {
        this.isNoResult = false;
      }
      this.showSearchDropdown = true;
    } catch (error) {}
  }

  navigateToCommon() {
    this.showProfileDropdown = false;
    singleSpa.navigateToUrl('/common');
    this.currentView = 'Common';
  }

  navigateToAccount() {
    this.showProfileDropdown = false;
    singleSpa.navigateToUrl('/common/my-account');
    this.currentView = 'Common';
  }
  currentViewUpdate() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url;
        if (currentRoute === '/') {
          this.currentView = 'LAUNCHPAD';
        } else if (currentRoute.includes('common')) {
          this.currentView = 'Common';
        }
      }
    });
  }
  goToLogin() {
    singleSpa.navigateToUrl('/');
  }

  async getProfileImage() {
    try {
      if (this.userData.profilePicture) {
        this.isLoadProfilePic = true;
        const userInfo: IUserInfo = state.getUserInfo();
        const imgUrl: any = await lastValueFrom(
          this.userService.getUserFilesPresignedUrl(userInfo.profilePicture)
        );
        this.userData = {
          ...this.userData,
          profilePicture: imgUrl?.storage?.files?.[0].preSignedUrl,
        };
        this.isLoadProfilePic = false;
      }
    } catch (err) {}
  }
  async getWorkspaceLogos() {
    try {
      const memberWithLogos = await Promise.all(
        this.userData.member.map(async (data: any) => {
          if (data.workspace && data.workspace.logo) {
            const logoUrl: any = await lastValueFrom(
              this.userService.getUserFilesPresignedUrl(data.workspace.logo)
            );
            return {
              ...data,
              workspace: {
                ...data.workspace,
                logo: logoUrl?.storage?.files?.[0].preSignedUrl,
              },
            };
          }
          return data;
        })
      );
      this.userData = {
        ...this.userData,
        member: memberWithLogos,
      };
    } catch (err) {}
  }

  handleSearchDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  switchWorkspace(workspaceId: string) {
    let userInfo: IUserInfo = state.getUserInfo();
    userInfo = {
      ...userInfo,
      activeWorkspaceId: workspaceId,
    };
    localStorage.setItem('user-data', JSON.stringify(userInfo));
    singleSpa.navigateToUrl('/');
    window.location.reload();
  }

  toggleDropdown() {
    this.showNotification = !this.showNotification;
  }

  async downloadReport(dataResourceId?: string) {
    try {
      if (dataResourceId) {
        this.isResourceLoading.resourceId = dataResourceId;
        this.loadingService.setLoading(true);
        const report = await lastValueFrom(
          this.dataResourceService.getDataResourceById(dataResourceId, true)
        );
        this.dataResourceService.downloadFile(
          report.storage.files[0].preSignedUrl,
          report.storage.files[0].origFileName
        );
      }
    } catch {
      this.loadingService.setLoading(false);
    }
  }

  getInitialLetter(): string {
    return this.userData.firstName
      ? this.userData.firstName.charAt(0).toUpperCase()
      : '';
  }

  isScrollAtBottom(element: HTMLElement): boolean {
    return element.scrollHeight - element.scrollTop === element.clientHeight;
  }

  isReportLoading(dataResourceId: string) {
    return this.isResourceLoading.loading &&
      dataResourceId == this.isResourceLoading.resourceId;
  }
}
