import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import { Menu, MapPin, User, ChevronDown, ChevronUp } from 'angular-feather/icons';

const icons = {
  Menu,
  MapPin,
  User,
  ChevronDown,
  ChevronUp
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }