<div #videoContainer class="video-box">
  @if (isVideoLoaded) {
  <div class="video-controls-container" [style.opacity]="displayControllsOpacity">
    @if (!liveStream) {
    <button class="rewind ripple" (click)="rewindTime()">
      <svg viewBox="0 0 24 24">
        <path fill="#ffffff"
          d="M12.5,3C17.15,3 21.08,6.03 22.47,10.22L20.1,11C19.05,7.81 16.04,5.5 12.5,5.5C10.54,5.5 8.77,6.22 7.38,7.38L10,10H3V3L5.6,5.6C7.45,4 9.85,3 12.5,3M10,12V22H8V14H6V12H10M18,14V20C18,21.11 17.11,22 16,22H14A2,2 0 0,1 12,20V14A2,2 0 0,1 14,12H16C17.11,12 18,12.9 18,14M14,14V20H16V14H14Z" />
      </svg>
    </button>
    }
    <button class="play-pause ripple" (click)="playPause()">
      <svg class="playing" [style.display]="isPlaying ? 'none' : ''" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <polygon points="5 3 19 12 5 21 5 3"></polygon>
      </svg>
      <svg class="paused" [style.display]="isPlaying ? '' : 'none'" viewBox="0 0 24 24">
        <rect x="6" y="4" width="4" height="16"></rect>
        <rect x="14" y="4" width="4" height="16"></rect>
      </svg>
    </button>
    @if (!liveStream) {
    <button class="fast-forward ripple" (click)="forwardTime()">
      <svg viewBox="0 0 24 24">
        <path fill="#ffffff"
          d="M10,12V22H8V14H6V12H10M18,14V20C18,21.11 17.11,22 16,22H14A2,2 0 0,1 12,20V14A2,2 0 0,1 14,12H16C17.11,12 18,12.9 18,14M14,14V20H16V14H14M11.5,3C14.15,3 16.55,4 18.4,5.6L21,3V10H14L16.62,7.38C15.23,6.22 13.46,5.5 11.5,5.5C7.96,5.5 4.95,7.81 3.9,11L1.53,10.22C2.92,6.03 6.85,3 11.5,3Z" />
      </svg>
    </button>
    }
  </div>
  }
  <div class="video-section">
    <!--video section-->
    @if (liveStream) {
    <video [class.d-none]="!isVideoLoaded" #video>
      <track src="" kind="subtitles" srclang="en" label="English" />
      <!-- Add description file -->
      <track src="" kind="descriptions" srclang="en" label="English" />
      <!-- Fallback content -->
      Your browser does not support the video tag.
    </video>
    } @else {
    <video [class.d-none]="!isVideoLoaded" #video [src]="videoUrl" type="video/mp4">
      <track src="" kind="subtitles" srclang="en" label="English" />
      <!-- Add description file -->
      <track src="" kind="descriptions" srclang="en" label="English" />
      <!-- Fallback content -->
      Your browser does not support the video tag.
    </video>
    }
    <!--end of video section-->
    @if (liveStream) {
    <div class="live-stream d-flex">
      <div class="live-rounded-icon"></div>
      <div class="live-text">LIVE</div>
    </div>
    }
    <div #controlsContainer class="controls-container" [style.opacity]="displayControllsOpacity">
      <div class="progress-controls">
        <div #progressBar class="progress-bar">
          <div class="watched-bar" [style.width]="watchedProgress + '%'"></div>
          <div class="loaded-bar" [style.width]="loadPercentage + '%'"></div>
          <div class="playhead"></div>
        </div>
      </div>
      <div class="controls row align-items-center">
        <div class="col-md-4 text-start d-flex control-btns align-items-center">
          <button class="video-control-action-btn" type="button" (click)="playPause()">
            <img [src]="'/images/'+((!isPlaying)?'play':'pause')+'.svg' | assetUrl" class="video-controls-img" alt="video controls" />
          </button>
          <button class="video-control-action-btn" (click)="rewindTime()">
            <img  [src]="'/images/back.svg' | assetUrl" class="rewind-controls-img m-left-7" alt="audio controls" />
          </button>
          <button class="video-control-action-btn" (click)="forwardTime()">
            <img  [src]="'/images/next.svg' | assetUrl"  class="rewind-controls-img m-left-10" alt="audio controls" />
          </button>
          <button #volumeButton class="video-control-action-btn" (click)="toggleMute()">
            <img [src]="'/images/'+((!isAudioDisabled)?'volume':'Muted')+'.svg' | assetUrl" class="audio-controls-img m-left-7" alt="audio controls" />
          </button>
          @if(videoDuriation){
            <div class="duriation-text">{{durationRemaining}}/{{videoDuriation}}</div>
          }
        </div>
        <div class="col-md-4 text-center">
          @if (videoStreamId) {
          <p class="video-stream-title">{{ videoStreamId }}</p>
          }
        </div>
        <div class="col-md-4 text-end control-btn">
          @if (!liveStream) {
          <select #selectedSpeed (change)="setPlaybackSpeed(selectedSpeed.value)" class="speed-option">
            @for (speed of playbackSpeedOptions; track speed) {
            <option [value]="speed" [selected]="speed === currentPlaybackSpeed ? true : false">
              {{ speed.toFixed(2) }}x
            </option>
            }
          </select>
          }
          <button #fullScreenButton class="video-control-action-btn" (click)="toggleFullScreen()">
            <img [src]="'/images/'+((!isFullScreen)?'maximize-screen':'minimize-screen')+'.svg' | assetUrl" class="audio-controls-img" alt="screen controls" />
          </button>
        </div>
      </div>
    </div>
    <div  [class.d-none]="isVideoLoaded" class="stream-error">
      <div #message class="stream-error-text">
        {{ videoStreamErrorMsg }}
      </div>
    </div>
    <div [style.display]="!isVideoLoaded && !liveStream? '' : 'none'" class="video-loader"></div>
  </div>
</div>
