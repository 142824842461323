<!-- <div *ngIf="sidebarConfig.show" class="sidebar_container">
  <div class="sidebar_icon">
    <div *ngFor="let sidebar of sideBar.children">
      <div *ngIf="sidebar.show" class="icon_container" (click)="sideBarClicked(sidebar.icon)"
        [ngClass]="{ active: on_nav_tap == sidebar.icon }" data-bs-placement="right" data-bs-toggle="utm-tooltip"
        data-bs-custom-class="custom-tooltip" title="{{ sidebar.sidebarName }}">
        <img class="side-icon" [src]="'/sidebar/' + sidebar.icon + '.svg' | assetUrl" alt="{{ sidebar.icon }}" />
      </div>
    </div>
  </div>
</div> -->

<div *ngIf="sidebarConfig.show" class="sidebar_container">
  <div class="sidebar_icon">
    <ng-container *ngFor="let sidebar of sidebarConfig.children; let i = index">
      <div *ngIf="sidebar.show" class="icon_container" (click)="sideBarClicked(sidebar.icon)"
        [ngClass]="{ active: on_nav_tap == sidebar.icon }" data-bs-placement="right" data-bs-toggle="utm-tooltip"
        data-bs-custom-class="custom-tooltip" title="{{ sidebar.sidebarName }}">
        <img class="side-icon" [src]="'/sidebar/' + sidebar.icon + '.svg' | assetUrl" alt="{{ sidebar.icon }}" />
      </div>
      <!-- Render divider if index is 3, 6, or 9 -->
      <div *ngIf="(i + 1) === 4 || (i + 1) === 7 || (i + 1) === 10" class="divider"></div>
    </ng-container>
  </div>
</div>
