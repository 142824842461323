export const IMG_ICONS = {
  trash: '/icons/trash.svg',
  edit: '/icons/pencil.svg',
  chevronDown: '/icons/chevron-down.svg',
  menuVertical: '/icons/menu-vertical.svg',
  fileUpload: '/icons/attachment.svg'
}
export const ASSET_TEMPLATE_TXT = 'Asset Templates'
export const COMPONENT_TEMPLATE_TXT = 'Component Templates'
export const PLUS_ICON_TXT = '+'
export const REUSE_TXT = 'Reuse'
export const CREATE_BY_TXT = 'created by'
export const NEW_TXT = 'new'
export const VIEW_TXT = 'view'
export const TRUE_TXT = 'true'
export const DEFAULT_TEMPLATE_NAME = 'template name'
export const ASSET_TXT = 'asset'
export const SUCCESS_TXT = 'success'
export const ERROR_TXT = 'error'
export const BULK_CREATION_TXT = 'Bulk Creation'
export const DOWNLOAD_SAMPLE_CSV_TXT = 'Download Sample CSV'
export const CHOOSE_FILE_TXT = 'Choose file'
export const PLEASE_CHOOSE_CSV_TXT = 'Please choose a csv file'
export const MANUAL_ENTRY_TXT = 'Manual Entry (single)'
export const USING_CSV_BULK_TXT = 'Using .csv (bulk)'
export const DRAFT_TXT = 'DRAFT'
export const STREAM_SHORTLY_BEGAIN_TXT='Your video is loading, setting up the media server. Your stream will automatically start, and video recording will begin.'
export const FAILED_STREAM_VIDEO='Failed to stream video.'
export const DUPLICATE_TXT = 'duplicate'
