import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment.service';
import { LoadingService } from '../loading/loading.service';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl();

@Injectable({
  providedIn: 'root',
})
export class DataResourceService {
  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService
  ) {}

  getDataResourceById(dataResourceId: string, getPreSignedUrl: boolean) {
    return this.httpClient
      .get<any>(`${apiUrl}/data-resource/data-resource/${dataResourceId}`, {
        params: {
          pre_signed_url: getPreSignedUrl,
        },
      })
      .pipe();
  }

  downloadFile(signedUrl: string, filename: string): void {
    this.loadingService.setLoading(true);
    fetch(signedUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
        this.loadingService.setLoading(false);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        this.loadingService.setLoading(false);
      });
  }
}
