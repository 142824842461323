
export const REWIND_TIME = -10
export const FORWARD_TIME = 10
export const PLAYBACK_TIME = [1.00, 1.25, 1.50, 2.00, 3.00, 5.00]

export const MEDIA_SERVERS = {
    MEDIAMTX: 'mediaMTX',
}

export const RETRY_PAUSE = 2000

export const VIDEO_STATUS = {
    PLAYING: 'playing',
    PAUSED: 'paused'
}

export const DEFAULT_PLAYBACK_SPEED = "1.00"